<template>
  <div>
    <head-layout
      :head-btn-options="headBtnOptions"
      head-title="考试基本信息"
      @head-cancel="headCancel"
    ></head-layout>
    <form-layout
      ref="formLayout"
      :column="formColumn"
      :dataForm.sync="dataForm"
    ></form-layout>
    <head-layout
      head-title="参考人员"
    ></head-layout>
    <grid-layout
      ref="gridLayOut"
      :table-options="tableOptions"
      :table-data="tableData"
      :table-loading="tableLoading"
      :data-total="page.total"
      :page="page"
      @page-current-change="handleCurrentChange"
      @page-size-change="handleSizeChange"
      @page-refresh-change="onLoadExamUser"
      @gird-handle-select-click="selectionChange"
    ></grid-layout>
  </div>
</template>

<script>

import {mapGetters} from "vuex";

import FormLayout from "@/views/components/layout/form-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";

import {getDetail} from "@/api/training/eduexam";
import {getLineList} from "@/api/training/eduexamuser";
import {getList} from "@/api/system/user";


export default {
  name: "eduexamView",
  components: {
    FormLayout,
    HeadLayout,
    GridLayout,
  },
  data() {
    return {
      query: {},
      dataForm: {},
      saveLoading: false,
      tableLoading: false,
      tableData: [],
      handleSelectedValue: [], //操作后选中的值
      examuserData: [],
      selectData: [],
      allData: [],
      prop: undefined,
      type: undefined,
      page: {
        pageSize: 999,
        currentPage: 1,
        total: 0,
      },
    }
  },
  computed: {
    ...mapGetters(["permission", 'colorName']),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.eduexam_add, true),
        viewBtn: this.vaildData(this.permission.eduexam_view, true),
        delBtn: this.vaildData(this.permission.eduexam_delete, true),
        editBtn: this.vaildData(this.permission.eduexam_edit, true)
      };
    },
    headBtnOptions() {
      return [
        {
          label: this.$t('cip.cmn.btn.cancel'),
          emit: "head-cancel",
          type: "button",
          icon: "",
          btnOptType: 'cancel',
          loading: this.saveLoading,
        }
      ]
    },
    tableOptions() {
      return {
        menu: false,
        index: true,
        indexLabel: "序号",
        column: [
          {
            label: "登录账号",
            prop: "account",
            width: 400,
            align: 'left',
            overHidden: true,
          },
          {
            label: "用户姓名",
            prop: "realName",
            width: 400,
            align: 'left',
            overHidden: true,
          },
          {
            label: "所属公司",
            prop: "deptName",
            align: 'left',
            overHidden: true,
          }
        ]
      }
    },
    formColumn() {
      return [
        {
          label: "考试编号",
          prop: "code",
          readonly: true,
          width: 180,
          placeholder: "保存后自动生成",
        },
        {
          label: "考试试卷",
          prop: "paperName",
          placeholder: "请选择考试试卷",
          readonly: true,
        },
        // {
        //   label: "考试时间",
        //   prop: "examTime",
        //   type: "datetime",
        //   format: "yyyy-MM-dd HH:mm:ss",
        //   valueFormat: "yyyy-MM-dd HH:mm:ss",
        //   readonly: true,
        // },
        {
          label: "考试地点",
          prop: "examAddress",
          maxlength: 100,
          showWordLimit: true,
          readonly: true,
        },
        {
          label: "备注",
          prop: "note",
          type: "textarea",
          overHidden: true,
          span: 24,
          minRows: "2",
          maxRows: "4",
          maxlength: 100,
          showWordLimit: true,
          readonly: true,
        },
      ];
    },
  },
  methods: {
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    onLoad(page, params = {}) {
      this.loading = true;
      getList(
        {
          ...params, ...this.query,
          current: page.currentPage,
          size: page.pageSize,
        }
      ).then((res) => {
        const data = res.data.data;
        this.allData = data.records;
        for (let i = 0; i < this.allData.length; i++) {
          this.examuserData.push({
            label: this.allData[i].realName,
            key: this.allData[i].id,
            disabled: true
          });
        }
        this.loading = false;
      });
    },
    onLoadExamUser(page, params = {}) {
      this.loading = true;
      this.handleSelectedValue = [];
      if (!params.examId){
        params.examId = this.dataForm.id
      }
      getLineList(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.query)
      ).then((res) => {
        const data = res.data.data;
        this.page.total = data.total;
        this.tableData = data.records;
        this.loading = false;
        this.disabledErpItemList = this.tableData.map(e => e.userId+""+e.deptId);
      });

      getList(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.query)
      ).then((res) => {
        const data = res.data.data;
        this.examuserData = data.records;
        this.loading = false;
      });
    },
    formData(id) {
      getDetail(id).then((res) => {
        this.dataForm = res.data.data;
      });
    },
  },
  mounted() {
  },
  created() {
    if (this.$route.query.row) {
      let row = JSON.parse(this.$route.query.row);
      this.dataForm.id = row.id;
    }
    this.prop = this.$route.query.prop;
    this.type = this.$route.query.type;
    if (this.type == "add") {
      this.dataForm.id = "";
      this.onLoad(this.page);
    } else if (this.type == "edit") {
      let row = JSON.parse(this.$route.query.row);
      this.formData(this.dataForm.id);
      this.dataForm.paperName = row.paperName;
      this.query.examId = this.dataForm.id;
      this.onLoadExamUser(this.page);
    } else if (this.type == "view") {
      this.formData(this.dataForm.id);
      let row = JSON.parse(this.$route.query.row);
      this.dataForm.paperName = row.paperName;
      this.query.examId = this.dataForm.id;
      this.onLoadExamUser(this.page);
    }
  }
}
</script>

<style scoped>

</style>
